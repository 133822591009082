<template>
  <div class="wrapper">
    <img loading="lazy" alt="icon" id="img-top" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/emprendedores/collaborators.jpg" />
    <div class="background first">
      <div class="container">
        <h2>{{ $t('emprendedores.collaborators') }}</h2>
        <p>
          Dentro del CLUB Emprendedores puedes encontrar una serie de
          profesionales expertos que ofrecen sus servicios como
          colaboradores para todo el ecosistema emprendedor a unas
          condiciones preferentes.
        </p>
      </div>
    </div>
    <div class="background second">
      <div class="container">
        <div class="contentas">
          <h3>SMART eBUSINESS</h3>
          <p>
            Diseño y desarrollo de software, webs y apps para móvil. Focalizados
            en la importancia de la gestión de los recursos en el desarrollo del
            software. De este aprendizaje y muchos otros, creamos Smart eBusiness
            Technologies para ayudar a emprendedores y empresas a llevar a cabo
            sus proyectos de una forma eficiente, rápida y de calidad.
          </p>
          <p>
            El objetivo: alcanzar la excelencia tanto en el apartado técnico, como
            en el estético, cuidando mucho el apartado visual de nuestros proyectos.
          </p>
          <h4>SERVICIOS:</h4>
          <b-row>
            <b-col cols="12" class="col-md-6">
              <div class="bullet">
                <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
                <span> Diseño y desarrollo web</span>
              </div>
              <div class="bullet">
                <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
                <span> Creación de tiendas online (ecommerce)</span>
              </div>
              <div class="bullet">
                <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
                <span>
                  Diseño y desarrollo Apps nativas para smartphone
                  <ul>
                    <li>Android</li>
                    <li>iOS</li>
                  </ul>
                </span>
              </div>
              <div class="bullet">
                <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
                <span>
                  Consultoría tecnológica
                  <ul>
                    <li>Asesoramiento</li>
                  </ul>
                </span>
              </div>
              <div class="bullet">
                <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
                <span>
                  Emprendedores
                  <ul>
                    <li>Productos Mínimos Viables</li>
                    <li>Pruebas de concepto y adopción</li>
                    <li>Test A/B para validación de hipótesis</li>
                    <li>Lean Start Up!</li>
                  </ul>
                </span>
              </div>
            </b-col>
            <b-col cols="12" class="col-md-6">
              <div class="bullet">
                <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
                <span>
                  Desarrollo de plataformas cloud / web bajo demanda
                </span>
              </div>
              <div class="bullet">
                <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
                <span> WebServices</span>
              </div>
              <div class="bullet">
                <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
                <span>
                  Otros servicios:
                  <ul>
                    <li>Optimización posicionamiento SEO</li>
                    <li>
                      Optimización de páginas web para carga rápida y
                      mejora de posicionamiento
                    </li>
                    <li>Cambios sobre aplicaciones / web ya desarrolladas</li>
                  </ul>
                </span>
              </div>
            </b-col>
          </b-row>
          <div class="contact">
            <img loading="lazy" alt="icon" src="/images/emprendedores/logo-smartebusiness.png" />
            <div id="person">
              <p id="name">Costel Bozomala</p>
              <p id="contacts">
                <a href="tel:911128820">911 12 88 20</a>  / 
                <a href="mailto:marketing@aicadmba.com">marketing@aicadmba.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('emprendedores.collaborators'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('emprendedores.collaborators') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('emprendedores.collaborators') + ' - Club de Ejecutivos' },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  #img-top {
    width: 100%;
  }
  .wrapper {
    text-align: left;

    h2 {
      margin-bottom: 60px;
    }

    h3 {
      margin: 30px 0;
    }

    h4 {
      border-bottom: 3px solid #dedede;
      font-weight: 400;
      width: 50%;
      margin: 30px 0;
      font-size: 1.75rem;
    }

    .contact {
      display: flex;
      flex-wrap: wrap;
      margin: 40px 0;

      img {
        width: 150px;
        height: 150px;
      }

      #person {
        margin: auto auto auto 40px;

        #name {
          color: #009090;
          font-size: 1.4rem;
          font-weight: 700;
        }

        #contacts {
          font-weight: 700;

          a {
            color: unset;
          }
        }
      }
    }

    .contentas {
      margin-bottom: 60px;
      border-bottom: 3px solid #dedede;
    }

    .background.first {
      background: #f2f2f2;    
      padding: 50px 0;
    }

    .second {
      padding: 10px 0;

      .bullet {
        margin: 15px 0;
      }
    }
  }

  // @media screen and (max-width: 1199px) {
  //   .contact {
  //     #person {
  //       margin: 0 !important;
  //     }
  //   }
  // }

   @media screen and (max-width: 767px) {
     .contact {
      img {
        margin: auto;
      }

      #person {
        margin: auto !important;
        text-align: center;
      }
    }
   }
</style>